import React from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'reactstrap';
import { getCardOrders } from '../actions';
import moment from 'moment';
import Icon from '../../components/icon';
import NewOrderModal from './new-order-modal';

class CardOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewOrderModal: false,
    };
  }

  componentWillMount() {
    return this.props.dispatch(getCardOrders());
  }

  renderOrders() {
    return (
      <Table hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody>{this.props.cardOrders.map(o => this.renderOrderRow(o))}</tbody>
      </Table>
    );
  }

  renderOrderRow(order) {
    return (
      <tr
        key={order.orderId}
        onClick={() => this.props.router.push('/card-orders/' + order.orderId)}
      >
        <td>{moment(order.createdAt).fromNow()}</td>
        <td>{order.user.firstName + ' ' + order.user.lastName}</td>
      </tr>
    );
  }

  render() {
    return (
      <div>
        <div className='card'>
          <div className='card-header'>
            Orders
            <Button
              color='primary'
              className='pull-right'
              onClick={() => this.setState({ showNewOrderModal: true })}
            >
              <Icon name='plus' /> New Card
            </Button>
          </div>
          <div className='card-block'>{this.renderOrders()}</div>
        </div>
        <NewOrderModal
          show={this.state.showNewOrderModal}
          onClose={() => this.setState({ showNewOrderModal: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let cardOrders = state.cardOrders.sort(
    (a, b) => moment(a.createdAt) - moment(b.createdAt),
  );
  let page = ownProps.location.pathname.split('/').slice(-1)[0];
  if (page === 'card-orders') {
    page = 'pending';
  }
  cardOrders = cardOrders.filter(co => co.state === page);
  return {
    cardOrders,
    allowCreate: page === 'pending',
  };
};

export default connect(mapStateToProps)(CardOrders);
