import apiKeys from './api-keys';
import cards from './cards';
import cardActivators from './card-activators';
import cardOrders from './card-orders';
import categories from './categories';
import contacts from './contacts';
import notifications from './notifications';
import transactions from './transactions';
import user from './user';
import users from './users';
import modals from './modals';
import alerts from './alerts';
import tags from './tags';
import sheets from './sheets';
import rootCode from './root-code';
import userKyc from './kyc';
import applications from './applications';

const account = (
  state = { complete: false, loading: false, error: false },
  action,
) => {
  switch (action.type) {
    case 'ACCOUNT_INIT_START':
      return { complete: false, loading: true, error: false };
    case 'ACCOUNT_INIT_SUCCESS':
      return { complete: true, loading: false, error: false };
    case 'ACCOUNT_INIT_FAILED':
      return { complete: false, loading: false, error: false };
    case 'LOGGED_OUT':
      return { complete: false, loading: false, error: false };
    default:
      return state;
  }
};

export default {
  applications,
  apiKeys,
  cards,
  cardActivators,
  cardOrders,
  categories,
  contacts,
  notifications,
  transactions,
  user,
  users,
  modals,
  account,
  alerts,
  tags,
  sheets,
  rootCode,
  userKyc,
};
