import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import Button from '../../components/loading-button';
import { addAlert } from '../..//alerts';
import { createCardOrder, linkCard } from '../actions';

class NewOrderModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedUser: null,
      cardNumber: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.selectedUser === null) {
      this.setState({ selectedUser: nextProps.users[0].id });
    }
  }

  close() {
    this.props.onClose();
  }

  setCardNumber(orderId, cardNumber) {
    this.props
      .dispatch(linkCard(orderId, cardNumber))
      .then(r => {
        this.setState({ loading: false });
        this.close();
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.dispatch(addAlert('Oops!', error.message, 'danger'));
      });
  }

  createOrder() {
    const cardNumber = this.state.cardNumber;
    const hasCardNumber =
      cardNumber === null ? false : cardNumber.trim().length > 0;

    this.setState({ loading: true });
    const user = this.props.users.find(x => x.id === this.state.selectedUser);
    this.props
      .dispatch(createCardOrder(user))
      .then(r => {
        if (hasCardNumber) {
          // TODO: Fill with real order id
          this.setCardNumber(r.orderId, cardNumber);
          return;
        }

        this.setState({ loading: false });
        this.close();
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.dispatch(addAlert('Oops!', error.message, 'danger'));
      });
  }

  render() {
    const userOptions = this.props.users
      .sort((a, b) => (a.firstName < b.firstName ? -1 : 1))
      .map(user => (
        <option value={user.id} key={user.id}>
          {user.firstName} {user.lastName}
        </option>
      ));
    return (
      <Modal isOpen={this.props.show} onClosed={this.close.bind(this)}>
        <ModalHeader>New Card Order</ModalHeader>
        <ModalBody>
          <form onSubmit={this.createOrder.bind(this)}>
            <FormGroup>
              <Label>User</Label>
              <Input
                type='select'
                placeholder='Select a user'
                onChange={e => this.setState({ selectedUser: e.target.value })}
              >
                {userOptions}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Card Number</Label>
              <Input
                type='text'
                placeholder='Enter a card number (optional)'
                onChange={e => this.setState({ cardNumber: e.target.value })}
              />
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={this.state.loading} onClick={this.close.bind(this)}>
            Cancel
          </Button>
          <Button
            loading={this.state.loading}
            color='primary'
            onClick={this.createOrder.bind(this)}
          >
            Create Order
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const users = state.users;
  return { users };
};

export default connect(mapStateToProps)(NewOrderModal);
