import React from 'react';
import { Button, Table, Badge, Input, Alert } from 'reactstrap';
import PageHeader from '../../components/page-header';
import ajax from '../../helpers/ajax';
import { withData, toCamelCaseKeys } from '../../helpers';

const billingMethods = {
  debit_orders: 'Debit orders',
  credit_cards: 'Credit cards',
  eft: 'Inbound EFTs',
  snapscan: 'SnapScan',
  external_payments: 'Custom payments',
};

class Settings extends React.Component {
  state = {
    organization: null,
    availableFeatures: [],
  };

  componentDidMount() {
    this.fetchOrganization();
    this.fetchAvailableFeatures();
  }

  async fetchOrganization() {
    const { organizationId } = this.props.params;

    const result = await ajax({
      path: `/client-apps/${organizationId}`,
      type: 'GET',
    });

    if (!result.error) {
      this.setState({ organization: toCamelCaseKeys(result) });
    } else {
      console.log(result);
    }
  }

  async fetchAvailableFeatures() {
    const result = await ajax({
      path: '/admin/features',
      type: 'GET',
    });

    if (!result.error) {
      this.setState({ availableFeatures: result.map(toCamelCaseKeys) });
    } else {
      console.log(result);
    }
  }

  enableFeature = async feature => {
    if (
      !window.confirm(
        `Are you sure you want to enable '${
          billingMethods[feature]
        }' on this organization?`,
      )
    ) {
      return;
    }

    const { organizationId } = this.props.params;

    const result = await ajax({
      path: `/admin/client-apps/${organizationId}/features/${feature}`,
      type: 'PUT',
    });

    if (!result.error) {
      this.props.fetchFeatures();
    } else {
      console.log(result);
    }
  };

  disableFeature = async feature => {
    if (
      !window.confirm(
        `Are you sure you want to disable '${
          billingMethods[feature]
        }' on this organization?`,
      )
    ) {
      return;
    }

    const { fetch, params } = this.props;

    const response = await fetch({
      path: `/admin/client-apps/${params.organizationId}/features/${feature}`,
      type: 'DELETE',
    });

    if (!response.error) {
      this.props.fetchFeatures();
    } else {
      console.log(response);
    }
  };

  publish = async () => {
    if (
      !window.confirm(
        'Are you sure you want to enable live mode for this organization?',
      )
    ) {
      return;
    }

    await ajax({
      path: `/admin/client-apps/${this.props.params.organizationId}/publish`,
      type: 'put',
    });

    window.location.reload();
  };

  sandbox = async () => {
    if (
      !window.confirm(
        'Are you sure you want to disable live mode for this organization?',
      )
    ) {
      return;
    }

    await ajax({
      path: `/admin/client-apps/${this.props.params.organizationId}/sandbox`,
      type: 'put',
    });

    window.location.reload();
  };

  renderBillingMethod = ([featureKey, title]) => {
    const billingMethod = this.props.features.find(
      feature => feature.featureKey === featureKey,
    );
    const billingMethodAvailable = this.state.availableFeatures.find(
      feature => feature.featureKey === featureKey,
    );
    const featureUnavailable = !(billingMethod || billingMethodAvailable);
    const isEnabled = billingMethod && billingMethod.production;

    let button;

    if (featureUnavailable) {
      // If feature is unavailable, we assume it's enabled by default
      button = <Button disabled>Disable</Button>;
    } else if (isEnabled) {
      button = (
        <Button onClick={() => this.disableFeature(featureKey)} color='danger'>
          Disable
        </Button>
      );
    } else {
      button = (
        <Button onClick={() => this.enableFeature(featureKey)} color='success'>
          Enable
        </Button>
      );
    }

    return (
      <tr key={featureKey}>
        <td>
          {title}{' '}
          {(featureUnavailable || isEnabled) && (
            <Badge color='success'>Enabled</Badge>
          )}{' '}
          <br /> <small>This feature can&#39;t be toggled yet</small>
        </td>
        <td>{billingMethodAvailable && billingMethodAvailable.description}</td>
        <td className='text-right'>{button}</td>
      </tr>
    );
  };

  render() {
    const { loading } = this.props;

    const mockEFTSettings = {
      bankName: 'FNB',
      branchNumber: '625001',
      accountNumber: '9271652717',
      paymentReferenceTitle: 'Policy number',
      paymentReference: 'XXX_POLICY_NUMBER_XXX',
    };

    const mockProductModules = [
      {
        id: 0,
        name: 'Funeral',
      },
      {
        id: 1,
        name: 'Device',
      },
      {
        id: 2,
        name: 'Team Life',
      },
      {
        id: 3,
        name: 'mock module',
      },
    ];

    if (loading)
      return (
        <div>
          <i>Loading</i>
        </div>
      );

    return (
      <div>
        <PageHeader title='Payment settings' />
        <Alert color='danger'>⚠️ This page is still under construction!</Alert>
        <h5 className='feather-light'>EFT settings</h5>
        <Table>
          <tbody>
            {Object.entries(mockEFTSettings).map((eftSetting, i) => {
              return (
                <tr key={`EFTSettingRow_${i}`}>
                  <th>{eftSetting[0]}</th>
                  <td>{eftSetting[1]}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button color='primary' onClick={() => alert('TODO EDIT')}>
          Edit
        </Button>
        <h5 className='feather-light padded'>Payment methods</h5>
        <Table>
          <thead>
            <tr>
              <th>Product module</th>
              {Object.values(billingMethods).map((billingMethod, i) => {
                return (
                  <th
                    key={`BillingMethodColumn_${i}`}
                    style={{ textAlign: 'center' }}
                  >
                    {billingMethod}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {mockProductModules.map((mockProductModule, i) => {
              return (
                <tr key={`ProductModuleRow_${i}`}>
                  <td>{mockProductModule.name}</td>
                  {Object.values(billingMethods).map((billingMethod, j) => {
                    return (
                      <td
                        key={`BillingMethodCheckbox_${i}_${j}`}
                        style={{ textAlign: 'center' }}
                      >
                        <Input type='checkbox' />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

Settings.defaultProps = {
  features: [],
};

export default withData({
  prop: 'features',
  path: props => `/admin/client-apps/${props.params.organizationId}/features`,
})(Settings);
