import React from 'react';
import { Route, IndexRedirect } from 'react-router';

// Components
import CardOrders from './components/orders';
import CardOrder from './components/order';
import { CardOrdersContainer } from '.';

export default (
  <Route name='cards' component={CardOrdersContainer}>
    <IndexRedirect to='pending' />
    <Route path='pending' component={CardOrders} />
    <Route path='shipped' component={CardOrders} filter='shipped' />
    <Route path=':orderId' component={CardOrder} />
  </Route>
);
