import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import Icon from '../../components/icon';

class Overview extends React.Component {
  render() {
    return (
      <Container>
        <Row>
          <Col sm='4'>
            <Card
              className='text-center'
              onClick={() => this.props.router.push('/users')}
            >
              <CardBody>
                <h1 style={{ lineHeight: '150px' }}>
                  {this.props.users.length}
                </h1>
                <h3>Manage users</h3>
              </CardBody>
            </Card>
          </Col>

          <Col sm='4'>
            <Card
              className='text-center'
              onClick={() => this.props.router.push('/applications')}
            >
              <CardBody>
                <h1 style={{ lineHeight: '150px' }}>
                  <Icon name='user-plus' />
                </h1>
                <h3>Invite users</h3>
              </CardBody>
            </Card>
          </Col>

          <Col sm='4'>
            <Card
              className='text-center'
              onClick={() => this.props.router.push('/card-orders')}
            >
              <CardBody>
                <h1 style={{ lineHeight: '150px' }}>
                  <Icon name='credit-card' />
                </h1>
                <h3>Load cards</h3>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const users = state.users;
  return { users };
};

export default connect(mapStateToProps)(Overview);
