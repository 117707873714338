import ajax from '../../helpers/ajax';
import { cardOrdersMap } from '../../helpers/maps';

export const getCardOrders = userId => {
  return dispatch => {
    return ajax({
      dispatch: dispatch,
      type: 'GET',
      path: '/admin/card-orders',
    }).then(result => {
      dispatch({
        type: 'SET_CARD_ORDERS',
        cardOrders: result.map(cardOrdersMap),
      });
    });
  };
};

export const createCardOrder = user => {
  return dispatch => {
    return ajax({
      dispatch: dispatch,
      type: 'POST',
      path: '/admin/card-order',
      data: {
        user_id: user.id,
      },
    }).then(result => {
      // window.location.reload();
      const cardOrder = {
        orderId: result.card_order_id,
        state: 'pending',
        user,
      };
      dispatch({ type: 'ADD_CARD_ORDER', cardOrder });
      return cardOrder;
    });
  };
};

export const linkCard = (cardOrderId, cardNumber) => {
  return dispatch => {
    return ajax({
      dispatch: dispatch,
      type: 'POST',
      path: '/admin/add-card',
      data: {
        card_order_id: cardOrderId,
        card_number: cardNumber,
      },
    }).then(result => {
      dispatch({
        type: 'UPDATE_CARD_ORDER',
        cardOrder: {
          orderId: cardOrderId,
          state: 'shipped',
          shippedAt: new Date(),
        },
      });
    });
  };
};
