import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Breadcrumb, { BreadcrumbProvider } from '../../components/breadcrumb';
import { withData } from '../../helpers';
import SidebarSecondary from '../../components/sidebar/SidebarSecondary';

class Organization extends React.Component {
  publish = () =>
    this.props.fetch({
      prop: 'organization',
      path: `/admin/client-apps/${this.props.params.organizationId}/publish`,
      type: 'put',
    });

  sandbox = () =>
    this.props.fetch({
      prop: 'organization',
      path: `/admin/client-apps/${this.props.params.organizationId}/sandbox`,
      type: 'put',
    });

  render() {
    if (this.props.loading) {
      return (
        <Container style={{ maxWidth: '100%' }}>
          <i>Loading...</i>
        </Container>
      );
    }

    if (this.props.organization) {
      const { productName, clientId } = this.props.organization;
      return (
        <BreadcrumbProvider
          value={{
            [clientId]: productName,
          }}
        >
          <div className='container-with-sidebar'>
            <SidebarSecondary
              router={this.props.router}
              route={this.props.route}
              subRoutes={sideBarSchema.routes(this.props.router.params)}
              title={sideBarSchema.name}
            />
            <Container>
              <Breadcrumb route={this.props.route} router={this.props.router} />
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col>{this.props.children}</Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </BreadcrumbProvider>
      );
    }

    return <div>Failed to load organization</div>;
  }
}

export default withData({
  prop: 'organization',
  path: props => `/client-apps/${props.params.organizationId}`,
})(Organization);

const sideBarSchema = {
  name: 'Organization',
  routes: params => {
    const { organizationId } = params;
    return [
      {
        title: true,
        name: 'Operations',
      },
      {
        name: 'Policies',
        url: `/organizations/${organizationId}/policies`,
      },
      {
        name: 'Policyholders',
        url: `/organizations/${organizationId}/policyholders`,
      },
      {
        title: true,
        name: 'Settings',
      },
      {
        name: 'Summary',
        url: `/organizations/${organizationId}/summary`,
      },
      {
        name: 'Team members',
        url: `/organizations/${organizationId}/team-members`,
      },
      {
        name: 'Product modules',
        url: `/organizations/${organizationId}/product-modules`,
      },
      {
        name: 'Customer notifications',
        url: `/organizations/${organizationId}/outbound-notifications`,
      },
      {
        name: 'Features',
        url: `/organizations/${organizationId}/features`,
      },
      {
        name: 'Exports',
        url: `/organizations/${organizationId}/exports`,
      },
      {
        name: 'Reports',
        url: `/organizations/${organizationId}/reports`,
      },
    ];
  },
};
