import ajax from '../../helpers/ajax';

export const fetchApplicationIdentity = (applicationId, idNumber) => {
  return dispatch => {
    return ajax({
      dispatch: dispatch,
      type: 'POST',
      path: '/identity',
      data: {
        id_number: idNumber,
      },
    }).then(result => {
      dispatch({
        type: 'FETCHED_APPLICATION_IDENTITY',
        homeAffairs: result,
        applicationId,
      });
    });
  };
};
