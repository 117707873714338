// import moment from 'moment';
// Template
// const init = [{
//   orderId: '1',
//   userId: '8b5d4526-f3a7-11e6-bda6-7b8e65e6b726',
//   addressId: '8e7d84ae-f3a8-11e6-9e80-977549216446',
//   state: 'pending', // shipped // activated
//   user: {
//     firstName: 'Louw',
//     lastName: 'Hopley',
//     email: 'louw@root.co.za',
//     cellphone: '+27741012432',
//   },
//   address: {
//     addressLine1: '20 Kloof Street',
//     addressLine2: 'Rozenhof Building',
//     city: 'Cape Town',
//     areaCode: '8001',
//   },
//   shippedAt: undefined,
//   activatedAt: undefined,
//   createdAt: moment(),
// }];

export default (state = [], action) => {
  switch (action.type) {
    case 'SET_CARD_ORDERS':
      return action.cardOrders; // TODO map

    case 'REMOVE_CARD_ORDER':
      return state.filter(c => {
        if (c.id === action.cardOrderId) {
          return false;
        }
        return true;
      });

    case 'UPDATE_CARD_ORDER':
      return state.map(c => {
        if (c.orderId === action.cardOrder.orderId) {
          c.shippedAt = action.cardOrder.shippedAt;
          c.state = action.cardOrder.state;
        }
        return c;
      });

    case 'ADD_CARD_ORDER':
      return [...state, action.cardOrder];

    default:
      return state;
  }
};
