// Template
// [
//   {
//     id,
//     userId,
//     last_4_digits,
//     createdAt,
//   },
// ]

export default (state = [], action) => {
  switch (action.type) {
    case 'SET_CARD_ACTIVATORS':
      return action.cardActivators;
    case 'REMOVE_ACTIVATOR':
      return state.filter(c => {
        if (c.id === action.activatorId) {
          return false;
        }
        return true;
      });
    default:
      return state;
  }
};
