import React from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Media,
  Input,
  Container,
  Row,
  Col,
  ButtonToolbar,
  FormGroup,
  Label,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import Button from '../../components/loading-button';
import FullLoading from '../../components/full-loading';
import {
  getApplications,
  approveApplication,
  approveDocument,
  rejectDocument,
  approveDetails,
} from '../actions';
import { fetchApplicationIdentity } from '../actions/identity';
import moment from 'moment';

const highestStatus = docs => {
  if (docs.find(d => d.state === 'approved')) {
    return 'approved';
  } else if (docs.find(d => d.state === 'in_review')) {
    return 'in_review';
  } else if (docs.find(d => d.state === 'declined')) {
    return 'declined';
  } else {
    return 'pending_upload';
  }
};

const prettyID = id => {
  try {
    return id.slice(0, 6) + ' ' + id.slice(6, 10) + ' ' + id.slice(10);
  } catch (error) {
    return '-';
  }
};

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      buttonsLoading: [],
      checkedCreateCardOrder: true,
      checkedSendActivationEmail: true,
      init100: true,
      init500: false,
    };
  }

  componentWillMount() {
    Promise.resolve()
      .then(r => {
        if (!this.props.application) {
          return this.props.dispatch(getApplications());
        }
      })
      .then(r => {
        if (
          this.props.application &&
          this.props.application.idNumber &&
          !this.props.application.identity
        ) {
          const applicationId = this.props.application.applicationId;
          const idNumber = this.props.application.idNumber;
          this.props.dispatch(
            fetchApplicationIdentity(applicationId, idNumber),
          );
        }
      });
  }

  renderBadge(status) {
    let style;
    let title;
    switch (status) {
      case 'pending_upload':
        style = 'default';
        title = 'Pending Upload';
        break;
      case 'in_review':
        style = 'warning';
        title = 'Requires Review';
        break;
      case 'approved':
        style = 'success';
        title = 'Approved';
        break;
      case 'declined':
        style = 'danger';
        title = 'Rejected';
        break;
      default:
        break;
    }
    return (
      <span className={'badge badge-' + style + ' float-right'}>{title}</span>
    );
  }

  approveApplication() {
    this.setState({ loading: true });
    const application = this.props.application;
    const createCardOrder = this.state.checkedCreateCardOrder;
    const sendActivationEmail = this.state.checkedSendActivationEmail;
    let initAmount;
    if (this.state.init100) {
      initAmount = 10000;
    } else if (this.state.init500) {
      initAmount = 50000;
    }
    const options = {
      createCardOrder,
      sendActivationEmail,
      initAmount,
    };
    this.props
      .dispatch(
        approveApplication(
          application.applicationId,
          application.idId,
          application.addressId,
          options,
        ),
      )
      .then(r => {
        this.setState({ loading: false }, () => {
          this.props.router.push('/applications');
        });
      })
      .catch(e => {
        // TODO error message on screen
        this.setState({ loading: false });
      });
  }

  approveDocument(docId) {
    this.setState({ buttonsLoading: [...this.state.buttonsLoading, docId] });
    this.props
      .dispatch(approveDocument(this.props.application.applicationId, docId))
      .then(r => {
        const bl = this.state.buttonsLoading.filter(b => b !== docId);
        this.setState({ buttonsLoading: bl });
      })
      .catch(e => {
        // TODO error message on screen
        const bl = this.state.buttonsLoading.filter(b => b !== docId);
        this.setState({ buttonsLoading: bl });
      });
  }

  rejectDocument(docId) {
    this.setState({ buttonsLoading: [...this.state.buttonsLoading, docId] });
    this.props
      .dispatch(rejectDocument(this.props.application.applicationId, docId))
      .then(r => {
        const bl = this.state.buttonsLoading.filter(b => b !== docId);
        this.setState({ buttonsLoading: bl });
      })
      .catch(e => {
        // TODO error message on screen
        const bl = this.state.buttonsLoading.filter(b => b !== docId);
        this.setState({ buttonsLoading: bl });
      });
  }

  approveDetails() {
    this.setState({
      buttonsLoading: [...this.state.buttonsLoading, 'home-affairs'],
    });
    this.props
      .dispatch(approveDetails(this.props.application.applicationId))
      .then(r => {
        const bl = this.state.buttonsLoading.filter(b => b !== 'home-affairs');
        this.setState({ buttonsLoading: bl });
      })
      .catch(e => {
        // TODO error message on screen
        const bl = this.state.buttonsLoading.filter(b => b !== 'home-affairs');
        this.setState({ buttonsLoading: bl });
      });
  }

  renderDetails(status) {
    const application = this.props.application;
    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th />
              <th>User Entered</th>
              <th>Home Affairs</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>First Name</td>
              <td>{application.firstName}</td>
              <td>{application.homeAffairs.firstName}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{application.lastName}</td>
              <td>{application.homeAffairs.lastName}</td>
            </tr>
            <tr>
              <td>ID Number</td>
              <td>{prettyID(application.idNumber)}</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Date of Birth</td>
              <td>
                {application.idNumber
                  ? moment(application.idNumber.slice(0, 6), 'YYMMDD').format(
                    'DD MMM YYYY',
                  )
                  : 'Not entered yet'}
              </td>
              <td>
                {application.homeAffairs.birthDate
                  ? moment(application.homeAffairs.birthDate).format(
                    'DD MMM YYYY',
                  )
                  : '-'}
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{application.email}</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Cellphone</td>
              <td>{application.cellphone}</td>
              <td>-</td>
            </tr>
          </tbody>
        </Table>
        {status !== 'approved' ? (
          <Button
            loading={this.state.buttonsLoading.includes('home-affairs')}
            onClick={this.approveDetails.bind(this)}
            color='primary'
          >
            Verify Matching Home Affairs Details
          </Button>
        ) : (
          undefined
        )}
      </div>
    );
  }

  renderDocumentRow(doc) {
    const elem = doc.url.endsWith('pdf') ? (
      <embed
        src={doc.url}
        style={{ maxWidth: '760px', width: '760px', height: '768px' }}
      />
    ) : (
      <Media
        object
        src={doc.url}
        style={{ maxWidth: '760px', width: '760px' }}
      />
    );
    return (
      <Row key={doc.id}>
        <Col sm={9}>
          <a href={doc.url} target='_blank' rel='noopener noreferrer'>
            {elem}
          </a>
        </Col>
        <Col sm={3}>
          {doc.state === 'in_review' ? (
            <ButtonToolbar>
              <Button
                loading={this.state.buttonsLoading.includes(doc.id)}
                onClick={this.approveDocument.bind(this, doc.id)}
                color='primary'
              >
                Approve
              </Button>
              <Button
                loading={this.state.buttonsLoading.includes(doc.id)}
                onClick={this.rejectDocument.bind(this, doc.id)}
                color='danger'
              >
                Reject
              </Button>
            </ButtonToolbar>
          ) : (
            undefined
          )}
          {doc.state === 'approved' ? (
            <span className='badge badge-success'>Approved</span>
          ) : (
            undefined
          )}
          {doc.state === 'declined' ? (
            <span className='badge badge-danger'>Rejected</span>
          ) : (
            undefined
          )}
        </Col>
        <Col sm={12}>
          <hr />
        </Col>
      </Row>
    );
  }

  renderIdDocuments(docs, status) {
    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th />
              <th>User Entered</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>First Name</td>
              <td>{this.props.application.firstName}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{this.props.application.lastName}</td>
            </tr>
            <tr>
              <td>ID Number</td>
              <td>{prettyID(this.props.application.idNumber)}</td>
            </tr>
          </tbody>
        </Table>
        <Container>{docs.map(doc => this.renderDocumentRow(doc))}</Container>
      </div>
    );
  }

  renderAddressDocuments(docs, status) {
    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th />
              <th>User Entered</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Address Line 1</td>
              <td>{this.props.application.addressLine1}</td>
            </tr>
            <tr>
              <td>Address Line 2</td>
              <td>{this.props.application.addressLine2}</td>
            </tr>
            <tr>
              <td>City</td>
              <td>{this.props.application.city}</td>
            </tr>
            <tr>
              <td>Area Code</td>
              <td>{this.props.application.areaCode}</td>
            </tr>
          </tbody>
        </Table>
        <Container>{docs.map(doc => this.renderDocumentRow(doc))}</Container>
      </div>
    );
  }

  renderCompleteApplication(ready) {
    if (ready) {
      return (
        <div>
          <FormGroup>
            <Label>Card options</Label>
            <br />
            <Label check>
              <Input
                type='checkbox'
                checked={this.state.checkedCreateCardOrder}
                onChange={() =>
                  this.setState({
                    checkedCreateCardOrder: !this.state.checkedCreateCardOrder,
                  })
                }
              />{' '}
              Create card order
            </Label>
          </FormGroup>
          <FormGroup>
            <Label check>
              <Input
                type='checkbox'
                checked={this.state.checkedSendActivationEmail}
                onChange={() =>
                  this.setState({
                    checkedSendActivationEmail: !this.state
                      .checkedSendActivationEmail,
                  })
                }
              />{' '}
              Send account activation email
            </Label>
          </FormGroup>
          <FormGroup>
            <Label>Fund account</Label>
            <br />
            <Label check>
              <Input
                type='checkbox'
                disabled={this.state.init500}
                checked={this.state.init100}
                onChange={() => this.setState({ init100: !this.state.init100 })}
              />{' '}
              Fund user&#39;s account with R100
            </Label>
          </FormGroup>
          <FormGroup>
            <Label check>
              <Input
                type='checkbox'
                disabled={this.state.init100}
                checked={this.state.init500}
                onChange={() => this.setState({ init500: !this.state.init500 })}
              />{' '}
              Fund user&#39;s account with R500
            </Label>
          </FormGroup>
          <Button
            onClick={this.approveApplication.bind(this)}
            color='primary'
            loading={this.state.loading}
          >
            Create Account
          </Button>
        </div>
      );
    } else {
      return (
        <div className='text-center'>
          All sections need to be completed before the user can be accepted.
        </div>
      );
    }
  }

  render() {
    if (!this.props.application) {
      return <FullLoading />;
    }
    const detailsStatus = this.props.application.homeAffairs.matchedAt
      ? 'approved'
      : 'in_review';
    const idDocs = this.props.application.documents.filter(
      d => d.type === 'id',
    );
    const idStatus = highestStatus(idDocs);
    const addressDocs = this.props.application.documents.filter(
      d => d.type === 'address',
    );
    const addressStatus = highestStatus(addressDocs);
    const applicationComplete =
      detailsStatus === 'approved' &&
      idStatus === 'approved' &&
      addressStatus === 'approved';

    // Application url
    const tokenData = {
      email: this.props.application.email,
      waiting_list_user_id: this.props.application.applicationId,
    };
    const token = btoa(JSON.stringify(tokenData)); // eslint-disable-line no-undef
    const url = 'https://root.co.za/apply/' + token;

    return (
      <div>
        <Card className='mb-4'>
          <CardHeader>Application</CardHeader>
          <CardBody>
            Application URL as in email:
            <br />
            <a href={url}>{url}</a>
          </CardBody>
        </Card>

        <Card className='mb-4'>
          <CardHeader>
            Home Affairs
            {this.renderBadge(detailsStatus)}
          </CardHeader>
          <CardBody>{this.renderDetails(detailsStatus)}</CardBody>
        </Card>

        <Card className='mb-4'>
          <CardHeader>
            Identity Verification
            {this.renderBadge(idStatus)}
          </CardHeader>
          <CardBody>{this.renderIdDocuments(idDocs, idStatus)}</CardBody>
        </Card>

        <Card className='mb-4'>
          <CardHeader>
            Address Verification
            {this.renderBadge(addressStatus)}
          </CardHeader>
          <CardBody>
            {this.renderAddressDocuments(addressDocs, addressStatus)}
          </CardBody>
        </Card>

        <Card className='mb-4'>
          <CardHeader>Application Approval</CardHeader>
          <CardBody>
            {this.renderCompleteApplication(applicationComplete)}
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const applicationId = ownProps.params.applicationId;
  const application = state.applications.find(
    app => app.applicationId === applicationId,
  );
  return {
    application,
  };
};

export default connect(mapStateToProps)(Application);
