import React from 'react';
import SidebarSecondary from '../components/sidebar/SidebarSecondary';
import { Container } from 'reactstrap';

export const CardOrdersContainer = props => {
  return (
    <div className='container-with-sidebar'>
      <SidebarSecondary
        router={props.router}
        route={props.route}
        subRoutes={sideBarSchema.routes()}
        title={sideBarSchema.name}
      />
      <Container>{props.children}</Container>
    </div>
  );
};

const sideBarSchema = {
  name: 'Cards',
  routes: () => {
    return [
      {
        title: true,
        name: 'Filter',
      },
      {
        name: 'Pending',
        url: '/card-orders/pending',
      },
      {
        name: 'Shipped',
        url: '/card-orders/shipped',
      },
    ];
  },
};
