import ajax from '../../helpers/ajax';
import { applicationsMap } from '../../helpers/maps';

export const getApplications = userId => {
  return dispatch => {
    return ajax({
      dispatch: dispatch,
      type: 'GET',
      path: '/admin/applications',
    }).then(result => {
      dispatch({
        type: 'SET_APPLICATIONS',
        applications: result.map(applicationsMap),
      });
    });
  };
};

export const approveApplication = (
  applicationId,
  idDocId,
  addressDocId,
  options,
) => {
  return dispatch => {
    return ajax({
      dispatch: dispatch,
      type: 'POST',
      path: '/admin/approve-application',
      data: {
        waiting_list_user_id: applicationId,
        id_doc_id: idDocId,
        address_doc_id: addressDocId,
        create_card_order: options.createCardOrder,
        send_activation_email: options.sendActivationEmail,
        init_amount: options.initAmount,
      },
    }).then(result => {
      dispatch({ type: 'APPROVED_APPLICATION', applicationId });
    });
  };
};

export const approveDocument = (applicationId, documentId) => {
  return dispatch => {
    return ajax({
      dispatch: dispatch,
      type: 'POST',
      path: '/admin/approve-document',
      data: {
        document_id: documentId,
      },
    }).then(result => {
      dispatch({ type: 'APPROVED_DOCUMENT', applicationId, documentId });
    });
  };
};

export const rejectDocument = (applicationId, documentId) => {
  return dispatch => {
    return ajax({
      dispatch: dispatch,
      type: 'POST',
      path: '/admin/reject-document',
      data: {
        document_id: documentId,
      },
    }).then(result => {
      dispatch({ type: 'REJECTED_DOCUMENT', applicationId, documentId });
    });
  };
};

export const approveDetails = applicationId => {
  return dispatch => {
    return ajax({
      dispatch: dispatch,
      type: 'POST',
      path: '/admin/approve-details',
      data: {
        waiting_list_user_id: applicationId,
      },
    }).then(result => {
      dispatch({ type: 'APPROVED_DETAILS', applicationId });
    });
  };
};

export const createUser = (firstName, email, bankAccess, offerzenMake) => {
  return dispatch => {
    return ajax({
      dispatch: dispatch,
      type: 'POST',
      path: '/admin/invite-beta-user',
      data: {
        first_name: firstName,
        email: email,
        bank_access: bankAccess,
        offerzen_make: offerzenMake,
      },
    });
  };
};
