import React from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Label,
  FormGroup,
  Input,
} from 'reactstrap';
import FullLoading from '../../components/full-loading';
import LoadingButton from '../../components/loading-button';
import moment from 'moment';
import { getCardOrders, linkCard } from '../actions';

class CardOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: '',
    };
  }

  componentWillMount() {
    return this.props.dispatch(getCardOrders());
  }

  renderLabel(state) {
    let style;
    let title;
    switch (state) {
      case 'pending':
        style = 'danger';
        title = 'Pending';
        break;
      case 'shipped':
        style = 'success';
        title = 'Shipped';
        break;
      case 'activated':
        style = 'success';
        title = 'Activated';
        break;
      default:
        break;
    }
    return (
      <Badge color={style} className='pull-right'>
        {title}
      </Badge>
    );
  }

  handleCardNumberChange(e) {
    let cardNumber = e.target.value;
    if (!/^\d+$/.test(cardNumber.slice(-1)) && cardNumber.slice(-1) !== ' ') {
      cardNumber = cardNumber.slice(0, -1);
    }
    this.setState({ cardNumber });
  }

  linkCard(e) {
    e.preventDefault();
    this.setState({ loading: true });
    this.props
      .dispatch(linkCard(this.props.cardOrder.orderId, this.state.cardNumber))
      .then(r => {
        this.setState({ loading: false });
      })
      .catch(e => {
        // TODO: error message on screen
        this.setState({ loading: false });
        console.error('ERROR LINKING CARD:', e);
      });
  }

  render() {
    if (!this.props.cardOrder) {
      return <FullLoading />;
    }
    return (
      <div>
        <Card className='mb-4'>
          <CardHeader>
            Order Details {this.renderLabel(this.props.cardOrder.state)}
          </CardHeader>
          <CardBody>
            <Table>
              <tbody>
                <tr>
                  <td>
                    <b>Recipient</b>
                  </td>
                  <td>
                    {this.props.cardOrder.user.firstName}{' '}
                    {this.props.cardOrder.user.lastName}
                    <br />
                    {this.props.cardOrder.user.email}
                    <br />
                    {this.props.cardOrder.user.cellphone}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Delivery Address</b>
                  </td>
                  {this.props.cardOrder.address ? (
                    <td>
                      {this.props.cardOrder.address.addressLine1}
                      <br />
                      {this.props.cardOrder.address.addressLine2}
                      <br />
                      {this.props.cardOrder.address.city}
                      <br />
                      {this.props.cardOrder.address.areaCode}
                    </td>
                  ) : (
                    <td>
                      <i>In person delivery</i>
                    </td>
                  )}
                </tr>
                <tr>
                  <td>
                    <b>Order Date</b>
                  </td>
                  <td>
                    {moment(this.props.cardOrder.createdAt).format(
                      'DD MMMM YYYY, h:mm',
                    )}
                    <br />({moment(this.props.cardOrder.createdAt).fromNow()})
                  </td>
                </tr>
                {this.props.cardOrder.shippedAt ? (
                  <tr>
                    <td>
                      <b>Shipped At</b>
                    </td>
                    <td>
                      {moment(this.props.cardOrder.shippedAt).format(
                        'DD MMMM YYYY, h:mm',
                      )}
                      <br />({moment(this.props.cardOrder.shippedAt).fromNow()})
                    </td>
                  </tr>
                ) : (
                  undefined
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
        {this.props.cardOrder.state === 'pending' ? (
          <Card className='mb-4'>
            <CardBody>
              <form onSubmit={this.linkCard.bind(this)}>
                <FormGroup>
                  <Label>Assign a physical card to this card order</Label>
                  <Input
                    type='text'
                    value={this.state.cardNumber}
                    placeholder='Enter card number'
                    onChange={this.handleCardNumberChange.bind(this)}
                  />
                </FormGroup>
                <LoadingButton
                  loading={this.state.loading}
                  color='primary'
                  onClick={this.linkCard.bind(this)}
                >
                  Link card
                </LoadingButton>
              </form>
            </CardBody>
          </Card>
        ) : (
          undefined
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const orderId = ownProps.params.orderId;
  const cardOrder = state.cardOrders.find(order => order.orderId === orderId);
  return { cardOrder };
};

export default connect(mapStateToProps)(CardOrder);
