import React from 'react';
import { Route, IndexRedirect, Redirect } from 'react-router';

// Components
import Organizations from './components/organizations';
import Organization from './components/organization';
import Templates from './components/templates';
import Template from './components/template';
import Settings from './components/settings';
import TeamMembers from './components/team-members/team-members';
import Policies from './components/policies';
import Policyholders from './components/policyholders';
import Policy from './components/policy';
import Policyholder from './components/policyholder';
import PolicyOverview from './components/policy/overview';
import PolicyPaymentMethod from './components/policy/payment-method';
import PolicyPayments from './components/policy/payments';
import { PolicyLedger } from './components/policy/ledger';
import PolicyPayment from './components/policy/payment';
import PolicyholderOverview from './components/policyholder/overview';
import PolicyholderPaymentMethods from './components/policyholder/payment-methods';
import PaymentMethod from './components/policyholder/payment-method';
import PublicAssets from './components/public-assets';
import Summary from './components/summary';
import OutboundNotifications from './components/outbound-notifications';
import { ExportsContainer } from './components/exports';
import organizationFeatures from './components/features/organization-features';
import ProductModules from './components/product-modules';
import { DataExportView } from './components/data-exports/views/data-export-view';
import TemplateView from './components/data-exports/template-view';
import { ReportsContainer } from './components/reports/report-routes';
import { ReportDetails } from './components/reports/components/report-view';

export default (
  <Route>
    <IndexRedirect to='approved' />

    <Route path='approved' component={Organizations} />
    <Route path='all' component={Organizations} />
    <Route path='testing' component={Organizations} />

    <Route path=':organizationId' name='organization' component={Organization}>
      <IndexRedirect to='summary' />
      <Route path='templates' component={Templates} />
      <Route path='templates/:templateKey' component={Template} />
      <Route path='settings' component={Settings} />
      <Route path='team-members' component={TeamMembers} />
      <Route path='features' component={organizationFeatures} />
      <Route path='product-modules' component={ProductModules} />
      <Route path='policies' component={Policies} />
      <Route path='policyholders' component={Policyholders} />
      <Route path='public-assets' component={PublicAssets} />
      <Route path='summary' component={Summary} />
      <Route path='outbound-notifications' component={OutboundNotifications}>
      </Route>

      <Redirect from='exports/templates' to='exports' />
      <Route path='exports' component={ExportsContainer} />
      <Route path='reports' component={ReportsContainer} />
      <Route path='reports/:reportId' component={ReportDetails}/>

      <Route
        path='exports'
        component={ExportsContainer}
      />
      <Route
        path='exports/:scheduledDataExportId'
        component={DataExportView}
      />
      <Route
        path='exports/templates/:templateId'
        component={TemplateView}
      />
      <Route path='exports/templates/:templateId' component={TemplateView} />

      <Redirect from='policies/:policyId' to='/policies/:policyId' />
      <Redirect from='policies/:policyId/*' to='/policies/:policyId/*' />
      <Redirect from='policyholders/:policyId' to='/policyholders/:policyId' />
      <Redirect from='policyholders/:policyId/*' to='/policyholders/:policyId/*' />
    </Route>

    {/* Top level routes for easier navigation during recon */}
    <Route path='/policies/:policyId' component={Policy}>
      <IndexRedirect to='overview' />
      <Route path='overview' component={PolicyOverview} />
      <Route path='payment-method' component={PolicyPaymentMethod} />
      <Route path='payments' component={PolicyPayments} />
      <Route path='payments/:paymentId' component={PolicyPayment} />
      <Route path='ledger' component={PolicyLedger} />
    </Route>

    <Route path='/policyholders/:policyholderId' component={Policyholder}>
      <IndexRedirect to='overview' />
      <Route path='overview' component={PolicyholderOverview} />
      <Route path='payment-methods' component={PolicyholderPaymentMethods} />
      <Redirect from='payment-methods/:paymentMethodId' to='/payment-methods/:paymentMethodId' />
    </Route>

    <Route path='/payment-methods/:paymentMethodId' component={PaymentMethod} />
  </Route>
);
