// Template
// [
//   {
//     createdAt,
//     id,
//     last4Digits,
//     name,
//     state,
//     virtual,
//   },
// ]

export default (state = [], action) => {
  switch (action.type) {
    case 'SET_CARDS':
      return action.cards;
    case 'NEW_CARD':
      return [...state, action.card];
    case 'FETCHED_CARD_DETAILS':
      const details = action.details;
      return state.map(c => {
        if (c.id === details.card_id) {
          c.cvv = details.cvv;
          c.cardNumber = details.card_number;
          c.validDate = details.valid_date;
          c.expiryDate = details.expiry_date;
        }
        return c;
      });
    case 'HIDE_CARD_DETAILS':
      return state.map(c => {
        delete c.cvv;
        delete c.cardNumber;
        delete c.validDate;
        delete c.expiryDate;
        return c;
      });
    case 'LOCKED_CARD':
      return state.map(c => {
        if (c.id === action.cardId) {
          c.state = 'locked';
        }
        return c;
      });
    case 'UNLOCKED_CARD':
      return state.map(c => {
        if (c.id === action.cardId) {
          c.state = 'active';
        }
        return c;
      });
    case 'ARCHIVED_CARD':
      return state.filter(c => {
        if (c.id === action.cardId) {
          return false;
        }
        return true;
      });
    case 'UPDATE_CARD_NAME':
      return state.map(c => {
        if (c.id === action.cardId) {
          c.name = action.cardName;
        }
        return c;
      });
    case 'UPDATE_CARD_LIMITS':
      return state.map(c => {
        if (c.id === action.cardId) {
          c.transactionLimit = action.transactionLimit;
          c.dailyLimit = action.dailyLimit;
          c.monthlyLimit = action.monthlyLimit;
        }
        return c;
      });
    case 'UPDATE_CARD_TAG':
      return state.map(c => {
        if (c.id === action.cardId) {
          c.tagId = action.tagId;
        }
        return c;
      });
    default:
      return state;
  }
};
