import moment from 'moment';

export const usersMap = user => {
  return {
    id: user.user_id,
    bankNumber: user.bank_number,
    walletId: user.wallet_id,
    email: user.email,
    appTOTP: user.app_totp,
    cellphone: user.cellphone || '',
    idNumber: user.id_number,
    companyName: user.company_name,
    dateOfBirth: moment(user.date_of_birth),
    firstName: user.first_name || '',
    lastName: user.last_name,
    profilePictureUrl: user.profile_picture_url,
    addressLine1: user.address_line_1,
    addressLine2: user.address_line_2,
    areaCode: user.area_code,
    city: user.city,
    country: 'South Africa',
    locked: user.locked,
    insuranceAccess: user.insurance_access,
    roles: user.roles,
    activationLink: user.activation_link,
  };
};

export const userMap = user => {
  return {
    id: user.user_id,
    bankNumber: user.bank_number,
    walletId: user.wallet_id,
    email: user.email,
    appTOTP: user.app_totp,
    cellphone: user.cellphone,
    companyName: user.company_name,
    dateOfBirth: moment(user.date_of_birth),
    firstName: user.first_name,
    lastName: user.last_name,
    profilePictureUrl: user.profile_picture_url,
    addressLine1: user.address_line_1,
    addressLine2: user.address_line_2,
    areaCode: user.area_code,
    city: user.city,
    country: 'South Africa',
    roles: user.roles,
  };
};

export const rootCodeMap = rootCode => {
  return {
    id: rootCode.root_code_id,
    userId: rootCode.user_id,
    cardId: rootCode.card_id,
    code: rootCode.code,
    configVariables: rootCode.config_variables,
    createdAt: moment(rootCode.created_at),
  };
};

export const cardMap = card => {
  return {
    id: card.card_id,
    userId: card.user_id,
    name: card.name,
    state: card.state,
    virtual: card.virtual,
    last4Digits: card.last_4_digits,
    createdAt: moment(card.created_at),
    transactionLimit: card.transaction_limit,
    dailyLimit: card.daily_limit,
    monthlyLimit: card.monthly_limit,
    tagId: card.tag_id,
  };
};

export const cardActivatorMap = cardActivator => {
  return {
    id: cardActivator.card_activator_id,
    userId: cardActivator.user_id,
    last4Digits: cardActivator.last_4_digits,
    createdAt: moment(cardActivator.created_at),
  };
};

export const transactionMap = transaction => {
  return {
    id: transaction.transaction_id,
    userId: transaction.user_id,
    cardId: transaction.card_id,
    categoryId: transaction.category_id,
    contactId: transaction.contact_id,
    amount: transaction.amount,
    // action: transaction.action,
    method: transaction.method,
    type: transaction.type,
    description: transaction.description,
    theirReference: transaction.their_reference,
    tagId: transaction.tag_ids ? transaction.tag_ids[0] : undefined,
    createdAt: moment(transaction.created_at),
  };
};

export const contactMap = contact => {
  return {
    id: contact.contact_id,
    userId: contact.user_id,
    name: contact.name,
    email: contact.email,
    cellphone: contact.cellphone,
    bankNumber: contact.bank_number,
    bankName: contact.bank_name,
    createdAt: moment(contact.created_at),
  };
};

export const categoryMap = category => {
  return {
    id: category.category_id,
    userId: category.user_id,
    name: category.name,
    softLimit: category.soft_limit,
    icon: category.icon,
    theme: category.theme,
    createdAt: moment(category.created_at),
  };
};

export const tagMap = tag => {
  return {
    id: tag.tag_id,
    userId: tag.user_id,
    name: tag.name,
    tagLimit: tag.tag_limit,
    createdAt: moment(tag.created_at),
  };
};

export const sheetsMap = sheet => {
  return {
    id: sheet.sheet_id,
    userId: sheet.user_id,
    name: sheet.name,
    url: sheet.url,
    tags: sheet.tags,
    categories: sheet.categories,
    createdAt: moment(sheet.created_at),
  };
};

export const apiKeyMap = apiKey => {
  return {
    id: apiKey.id,
    name: apiKey.name,
    safeToken: apiKey.safe_token,
    scope: apiKey.scope,
    createdAt: moment(apiKey.created_at),
  };
};

export const userKycMap = userKyc => {
  return {
    idState: userKyc.id_state,
    addressState: userKyc.address_state,
    idPhoto: userKyc.id_photo || undefined,
    addressPhoto: userKyc.address_photo || undefined,
  };
};

export const applicationsMap = a => {
  return {
    applicationId: a.application_id,
    email: a.email,
    firstName: a.first_name,
    lastName: a.last_name,
    idNumber: a.id_number,
    addressLine1: a.address_line_1,
    addressLine2: a.address_line_2,
    city: a.city,
    areaCode: a.area_code,
    cellphone: a.cellphone,
    documents: a.documents[0]
      ? a.documents
        .map(d => {
          return {
            id: d.id,
            state: d.state,
            createdAt: moment(d.created_at),
            type: d.type,
            url: d.url,
          };
        })
        .sort((a, b) => b.createdAt - a.createdAt)
      : [],
    homeAffairs: a.kyc
      ? {
        firstName: a.kyc.home_affairs_first_name,
        lastName: a.kyc.home_affairs_last_name,
        birthDate: a.kyc.home_affairs_birth_date,
        matchedAt: a.kyc.home_affairs_details_match_at
          ? moment(a.kyc.home_affairs_details_match_at)
          : false,
      }
      : {},
    idUrl: a.id_doc_url,
    addressUrl: a.address_doc_url,
    idId: a.id_doc_id,
    addressId: a.address_doc_id,
    createdAt: moment(a.created_at),
  };
};

export const cardOrdersMap = c => {
  return {
    orderId: c.card_order_id,
    userId: c.user_id,
    address_id: c.address_id,
    state: c.state,
    user: {
      firstName: c.user_first_name,
      lastName: c.user_last_name,
      email: c.user_email,
      cellphone: c.user_cellphone,
    },
    address: c.address_line_1
      ? {
        addressLine1: c.address_line_1,
        addressLine2: c.address_line_2,
        city: c.address_city,
        areaCode: c.addressAreaCode,
      }
      : null,
    shippedAt: c.shipped_at,
    activatedAt: c.activated_at,
    createdAt: c.created_at,
  };
};
